import React from "react"
import Layout from "../components/layout"
import Logo from "../components/images/logo"
import LogoMobileSmall from '../components/images/logo-mobile-small'
import Seo from "../components/seo"
import { motion } from 'framer-motion'
import AnimationFadeIn from "../animations/animation-fade-in";

const ImpressumPage = (props) => (
    <Layout>

        <Seo title="Impressum" />
        <h1 className="subpage--headline">impressum</h1>
        <Logo title="ps:dreizehn GmbH" />
        <LogoMobileSmall title="ps:dreizehn GmbH" />
        <div className="content-wrapper">
            <div className="content">
                <motion.div variants={AnimationFadeIn} transition="spring" initial="hidden" animate="visible" exit="exit">
                    <h2>Impressum</h2>

                    <p>
                        ps:dreizehn GmbH<br />
                        Paul-Seydel-Straße 13<br />
                        09212 Limbach-Oberfrohna
                    </p>

                    <p>
                        Telefon: <a href="tel:+4937228906314 ">+49 3722 8906314</a><br />
                        Telefax: +493722 600279<br />
                        Mail: <a href="mailto:info@psdreizehn.de">info@psdreizehn.de</a>
                    </p>

                    <p>
                        <strong>Vertreten durch:</strong><br />
                        Geschäftsführer Alexander Schuck
                    </p>

                    <p>
                        <strong>Registereintrag:</strong><br />
                        Eingetragen im Handelsregister.<br />
                        Registergericht: AG Chemnitz<br />
                        Registernummer: HRB 29345
                    </p>

                    <p>
                        <strong>Umsatzsteuer-ID:</strong><br />
                        Umsatzsteuer-Identifikationsnummer nach §27a Umsatzsteuergesetz: DE298085749
                    </p>

                    <p>
                        <strong>Verantwortlicher i.S. des §55 RStV:</strong><br />
                        Alexander Schuck<br />
                        Paul-Seydel-Straße 13<br />
                        09212 Limbach-Oberfrohna
                    </p>

                </motion.div>
            </div>
        </div>
    </Layout>
)

export default ImpressumPage